@import '../../../_theme_variables';
#tags_blog_block_left {
	.block_content {
		a {
			border: 1px solid $base-border-color;
			display: inline-block;
			float: left;
			font-size: 13px;
			font-weight: bold;
			line-height: 16px;
			margin: 0 3px 3px 0;
			padding: 4px 9px 5px;
		}
	}
}